<template>
  <div class="partner">
    <div class="container">
      <div class="about-title">
        <h1 class="primary-title">加入我们</h1>
        <h2 class="secondary-title">JOIN US</h2>
      </div>
      <div class="about-content">
        <div class="about-item-content">
          <div class="about-item-title">我们是</div>
          <div class="about-item-body">
            <p>嗨喵悦动是一个年轻的，充满活力，积极向上的团队</p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">我们需要</div>
          <div class="about-item-body">
            <p>有良好的沟通能力，较好的亲和力，较强的专业能力</p>
            <p>精力充沛，喜爱竞争，不惧挑战</p>
            <p>具有敬业精神及团队合作意识</p>
            <p>正直诚信，忠于企业，传承企业文化，愿意与企业一起发展成长</p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">高级Java工程师</div>
          <div class="about-item-body">
            <p>【职位描述】</p>
            <p>
              结合业务需求和企业实际情况，提供前瞻性的可行技术设计、方案，指导和优化技术选型；
            </p>
            <p>难点攻克，技术输出，指导初级工程师，促进团队共同成长；</p>
            <p>【职位要求】</p>
            <p>基础扎实, 并有五年以上实践开发经验；</p>
            <p>
              精通JAVA
              EE中互联网常用技术和框架，包括但不限于Spring、Mybatis、dubbo、k8s、elasticsearch等；
            </p>
            <p>
              熟悉MySQL应用开发，熟悉数据库原理和常用性能优化技术，以及NoSQL、Queue的原理、使用场景以及限制；
            </p>
            <p>
              熟悉HTTP协议、缓存、序列化、NIO、RPC框架等，并且有相关实践经验；
            </p>
            <p>有较强的逻辑思维能力，善于分析、归纳、解决问题；</p>
            <p>
              参与过大型分布式系统设计开发，熟悉CAP、一致性Hash、Paxos等理论和算法，对高可用、高性能、可扩展、可伸缩系统有深刻的理解；
            </p>
            <p>具有敏捷开发经验，实践过持续集成和自动化</p>
            <p>211、985计算机相关专业优先考虑。</p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">前端工程师</div>
          <div class="about-item-body">
            <p>【职位描述】</p>
            <p>独立负责移动端项目开发和维护，3年以上前端项目经验</p>
            <p>
              参与前端框架和组件易用性改善、基础建设和推动新框架技术的落地，参与前端架构改进，提升团队开发效率
            </p>
            <p>持续学习和分享前端前沿技术</p>
            <p>
              配合团队整体建设，协助构建优秀的团队开发环境和基础设施，指导、分享前端技术经验，帮助团队共同成长
            </p>
            <p>【职位要求】</p>
            <p>熟悉React/Vue等主流框架中的一种</p>
            <p>对前端工程化、组件化、模块化有自己的理解和总结</p>
            <p>能够持续学习和分享前端前沿技术</p>
            <p>对可用性、用户体验等相关知识有深刻的认识和实践经验；</p>
            <p>
              对前端技术有持续的热情，个性乐观开朗，逻辑性强，善于和各种背景的人合作；
            </p>
            <p>211、985计算机相关专业优先考虑。</p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">视觉设计专家</div>
          <div class="about-item-body">
            <p>【职位描述】</p>
            <p>
              对设计和创新充满激情，对市场动向及行业发展趋势高度关注，对视觉表达敏感度较高；
            </p>
            <p>
              具有5年以上互联网界面设计或品牌平面设计经验，具备多平台，多行业，跨领域视觉设计项目经验；
            </p>
            <p>
              有敏锐的观察力及综合分析能力，能够将商业、品牌、传播、产品等因素与视觉设计知识相结合。
            </p>
            <p>
              优秀的视觉设计输出及呈现能力，可进行不同风格视觉定义，细节导向，能够制定清晰合理的视觉原则和视觉规范；
            </p>
            <p>【职位要求】</p>
            <p>
              强学习能力。能够快速学习领域知识，并能够保持不断学习新的方法、知识的动力；
            </p>
            <p>
              具有项目管理敏感度，具备质量把控、时间管理、客户沟通管理及风险应对能力；
            </p>
            <p>具有项目执行力，能够胜任多项目，多任务的并行；</p>
            <p>优秀的内外部沟通能力，有担当，踏实靠谱。</p>
            <p>美术、设计相关专业者优先考虑。</p>
          </div>
        </div>

        <div class="about-item-content">
          <div class="about-item-title">欢迎有志人士加入</div>
          <div class="about-item-body">
            <p>有意者发送简历到: job@hudongmiao.com</p>
            <p>公司地址：上海 - 普陀区 - 中江路118号</p>
          </div>
        </div>
      </div>
    </div>
    <myFooter />
  </div>
</template>

<script>
import myFooter from '@/components/myFooter.vue';

export default {
  name: 'join',
  metaInfo: {
    title: '嗨喵悦动 - 嗨喵悦动登录_嗨喵悦动大屏互动',
    meta: [
      {
        name: 'keywords',
        content:
          '嗨喵悦动,嗨喵悦动大屏互动,嗨喵悦动官网,嗨喵悦动登录,微信墙,微信互动,现场互动,嗨现场',
      },
      {
        name: 'description',
        content:
          '嗨喵悦动是一款免费便捷炫酷的活动大屏幕现场互动软件，适用于企业年会、酒吧、婚礼、商业庆典峰会等活动现场，包括微信上墙送祝福、扫码签到、抽奖投票、摇一摇红包雨等有趣的大屏互动游戏，让活动更精彩！',
      },
    ],
  },
  components: { myFooter },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  position: relative;
}
.banner > .img {
  width: 100%;
  height: 50%;
  background-color: #f9f9f9;
  vertical-align: middle;
  border: 0;
}

.about-title {
  top: 15%;
  padding: 15px 37.5px;
  width: 100%;
  color: #000;
  position: relative;
}

.primary-title {
  font-size: 30px;
}

.secondary-title {
  margin-top: 7.5px;
  padding-left: 22.5px;
  font-size: 15px;
  font-weight: 500;
  font-family: "Times New Roman", serif;
}

.container {
  position: relative;
}

.about-content {
  margin: 0px auto 0;
  padding: 22.5px 37.5px;
  width: 100%;
  max-width: 817.5px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 15px 11.25px -3.75px #d3cfc5;
}

.about-item-content {
  margin-top: 7.5px;
}

.about-item-title {
  color: #ff4141;
  font-size: 12px;
  margin-bottom: 15px;
  font-weight: bolder;
}

.about-item-body {
  margin-bottom: 22.5px;
  line-height: 1.5;
  text-indent: 2em;
}

p {
  margin: 0 0 7.5px;
}
</style>
